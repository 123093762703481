import axios, { AxiosResponse } from "axios";
import { getSession } from "next-auth/react";

import useStore from "store/store";

export class BridgeError extends Error {
  statusCode: number;
  message: string;

  constructor(statusCode: number, message: string) {
    super(message);
    this.statusCode = statusCode;
    this.message = message;

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, BridgeError.prototype);
  }
}

export type ErrorBody = {
  error: string;
};

export type EventType = "resource"; // Can be extended to support other types (e.g. audit)

export const endpoint = {
  about: "/about",
  myInstanceTypes: "/my/instance-types",
  myUser: "/my/user",
  myOrg: "/my/org",
  stacks: "/my/stacks",
  stackByID: function (id: number) {
    return `/my/stacks/${id}`;
  },
  appsByStackID: function (id: number) {
    return `/my/stacks/${id}/applications`;
  },
  myOrgInstances: "/my/org/instance-types",
  orgPutInstanceType: function (instanceID: string) {
    return `/my/org/instance-types/${instanceID}`;
  },
  orgDeleteInstanceType: function (instanceID: string) {
    return `/my/org/instance-types/${instanceID}`;
  },
  workflows: "/my/workflows",
  patchWorkflow: function (id: string) {
    return `/my/workflows/${id}`;
  },
  deleteWorkflow: function (id: string) {
    return `/my/workflows/${id}`;
  },
  orgWorkflows: "/my/org/workflows",
  orgDeleteWorkflow: function (workflowID: string) {
    return `/my/org/workflows/${workflowID}`;
  },
  organization: "/organizations",
  patchOrganization: function (id: number) {
    return `/organizations/${id}`;
  },
  users: function (id: string | number) {
    return `/organizations/${id}/users`;
  },
  userByID: function (orgID: string | number, id: string) {
    return `/organizations/${orgID}/users/${id}`;
  },
  postUser: function (orgID: string | number) {
    return `/organizations/${orgID}/users`;
  },
  patchUser: function (orgID: string | number, username: string) {
    return `/organizations/${orgID}/users/${username}`;
  },
  // User is "deleted" by patching the "enabled" field, there is no true delete.
  // deleteUser: function (orgID: string | number, username: string) {
  //   return `/organizations/${orgID}/users/${username}`;
  // },
  postUserGroup: function (orgID: string | number, username: string) {
    return `/organizations/${orgID}/users/${username}/groups`;
  },
  deleteUserGroup: function (orgID: string | number, username: string, group: string) {
    return `/organizations/${orgID}/users/${username}/groups/${group}`;
  },
  groupsByOrgID: function (id: string | number) {
    return `/organizations/${id}/groups`;
  },
  deleteGroup: function (id: string | number, group: string) {
    return `/organizations/${id}/groups/${group}`;
  },
  getMyDisks: "/my/disks",
  getMyDisk: function (id: string) {
    return `/my/disks/${id}`;
  },
  patchMyDisk: function (id: string) {
    return `/my/disks/${id}`;
  },
  deleteMyDisk: function (id: string) {
    return `/my/disks/${id}`;
  },
  getMyOrgDisks: "/my/org/disks",
  deleteMyOrgDisk: function (id: string) {
    return `/my/org/disks/${id}`;
  },
  moveDisk: function (from: string, to: string) {
    return `/my/org/disks/move/from/${from}/to/${to}`;
  },
  events: function (type: EventType) {
    return `/my/org/events/${type}`;
  },
  myOrgBlueprints: "/my/org/blueprints",
  myOrgBlueprintElections: "/my/org/elections",
  deleteMyOrgBlueprintElections: function (id: number) {
    return `/my/org/elections/${id}`;
  },
  migrate: "/disks/migrate",
};

function wait(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

const apiClient = axios.create();

apiClient.interceptors.request.use(
  async (config) => {
    const creds = useStore.getState().creds;
    config.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${creds.token}`,
    };
    config.baseURL = useStore.getState().clientConfig?.bapiURL;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && error?.response?.data?.error.includes("expired") && !originalRequest._retry) {
      originalRequest._retry = true;
      const s = await getSession();
      useStore.setState({
        creds: {
          token: s?.accessToken,
          expires: s?.tokenExpires,
        },
      });
      axios.defaults.headers.common["Authorization"] = "Bearer " + s.accessToken;
      await wait(useStore.getState().clientConfig?.refreshTokenDelay); // This prevent an edge case where the API server throws "token used before issue" error. Probably related to clock skew.
      return apiClient(originalRequest);
    }
    return Promise.reject(error);
  }
);

export const handleAllPromises = (results: PromiseSettledResult<AxiosResponse>[]) => {
  const fulfilled = results.filter(
    (result) => result.status === "fulfilled"
  ) as PromiseFulfilledResult<AxiosResponse>[];
  const responses = fulfilled.map((result) => result.value);

  const rejected = results.filter((result) => result.status === "rejected") as PromiseRejectedResult[];
  const errors = rejected.map((result) => result.reason);

  return {
    successes: responses,
    failures: errors,
  };
};

export const client = async (
  url: string,
  method?: "GET" | "POST" | "PATCH" | "DELETE",
  body?: unknown
): Promise<AxiosResponse> => {
  const resp: AxiosResponse = await apiClient(url, {
    method: method,
    data: body,
  });
  resp["ok"] = resp?.status === 200;

  return resp;
};
