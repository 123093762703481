import * as React from "react";

import * as Sentry from "@sentry/nextjs";

import { MyOrgContext } from "context/myorgcontext";
import useBridgeSWR from "hooks/BridgeSWRClient";
import { endpoint } from "lib/bridgeClient";
import useStore from "store/store";

const MyOrg = ({ children }) => {
  const config = useStore((state) => state.clientConfig);
  const validCreds = useStore((state) => state.valid);

  // This is necessary to rerender this component on cred change, which is necessary
  // to invoke the api endpoint when credentials become available
  useStore((state) => state.creds);

  const dispatch = validCreds() && config?.bapiURL;
  const { data: myOrg, error } = useBridgeSWR<MyOrg>(dispatch ? endpoint.myOrg : null);

  if (error) {
    Sentry.captureException(error);
    return <div className="centered-disp">Unable to determine organization. Contact support.</div>;
  }

  if (myOrg) {
    Sentry.setTag("org", myOrg?.name);
  }

  return <MyOrgContext.Provider value={myOrg}>{children}</MyOrgContext.Provider>;
};

export default MyOrg;
