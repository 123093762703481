import useStore from "store/store";

export type ClientConfig = {
  bapiURL: string;
  refreshTokenDelay: number; // Milliseconds
};

export const setClientConfig = (config: ClientConfig) => {
  useStore.setState({ clientConfig: config });
};

export const getClientConfig = () => {
  return useStore.getState().clientConfig;
};
