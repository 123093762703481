import React from "react";

import ConstructionIcon from "@mui/icons-material/Construction";
import GavelIcon from "@mui/icons-material/Gavel";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Divider as MUIDivider } from "@mui/material";
import Box from "@mui/material/Box";
import { styled, ThemeProvider } from "@mui/material/styles";

import { MyOrgContext } from "context/myorgcontext";
import useBridgeClient from "hooks/BridgeSWRClient";
import { endpoint } from "lib/bridgeClient";
import theme, { devTheme, testTheme } from "theme";

const Container = styled("footer")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const Company = styled("div")(() => ({
  float: "left",
  fontSize: "12px",
}));

const Divider = styled(MUIDivider)(() => ({
  width: "1px",
}));

const TierIndicator = styled("div")(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "12px",
  textTransform: "uppercase",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  minWidth: "50px",
}));

enum Tier {
  Dev,
  Stage,
  Test,
  Validation,
  Prod,
  Unknown,
}

function determineTier(apiTier: string): Tier {
  if (!apiTier) {
    return Tier.Unknown;
  }

  const compareString = apiTier.toLowerCase();
  switch (compareString) {
    case "development":
      return Tier.Dev;
    case "staging":
      return Tier.Stage;
    case "test":
      return Tier.Test;
    case "validation":
      return Tier.Validation;
    case "production":
      return Tier.Prod;
    default:
      return Tier.Unknown;
  }
}

function getTierTheme(tier: Tier) {
  switch (tier) {
    case Tier.Dev:
    case Tier.Stage:
    case Tier.Unknown:
      return devTheme;
    case Tier.Test:
    case Tier.Validation:
      return testTheme;
    case Tier.Prod:
      return theme;
    default:
      return devTheme; // Returns the same for PROD and UNKNOWN -- Assuming it's best to be careful when tier is unknown
  }
}

function getTierIcon(tier: Tier) {
  const iconStyles = { fontSize: "16px", marginRight: "5px", marginBottom: "1px" };

  switch (tier) {
    case Tier.Dev:
    case Tier.Stage:
    case Tier.Unknown:
      return <ConstructionIcon sx={iconStyles} />;
    case Tier.Test:
    case Tier.Validation:
      return <GavelIcon sx={iconStyles} />;
    case Tier.Prod:
      return <LightbulbIcon sx={iconStyles} />;
    default:
      // Should not be possible to reach, so DEFINITELY flag it if we do.
      return <QuestionMarkIcon sx={iconStyles} />;
  }
}

const Footer: React.FC = () => {
  const org = React.useContext(MyOrgContext);

  const { data: about } = useBridgeClient<About>(endpoint.about);

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "15px",
          width: "fit-content",
          "& hr": {
            mx: 2,
          },
        }}
      >
        <Company>&copy; {new Date().getFullYear()} Metrum Research Group</Company>
        <Divider orientation="vertical" flexItem />
        <div style={{ fontSize: "12px" }}>{org?.name}</div>
        <Divider orientation="vertical" flexItem />
        <ThemeProvider theme={getTierTheme(determineTier(about?.tier))}>
          <TierIndicator>
            {getTierIcon(determineTier(about?.tier))}
            {about?.tier ? about.tier : ""}
          </TierIndicator>
        </ThemeProvider>
      </Box>
    </Container>
  );
};

export default Footer;
