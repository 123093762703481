import * as React from "react";

import { useSession } from "next-auth/react";

import useStore from "store/store";

const AccessToken = ({ children }) => {
  const creds = useStore((state) => state.creds);
  const setCreds = useStore((state) => state.setCreds);

  const { data: session } = useSession();

  React.useEffect(() => {
    // Conditional setting of token based on expiry value to prevent
    // unnecessary state change, and only do so when a valid condition
    // warrants state update.
    if (session && session.tokenExpires > creds.expires) {
      setCreds({
        token: session?.accessToken,
        expires: session?.tokenExpires,
      });
    }
  }, [session, creds, setCreds]);

  return children;
};

export default AccessToken;
