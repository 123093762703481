import * as Sentry from "@sentry/nextjs";
import download from "downloadjs";
import { toast } from "react-toastify";

import { useErrorToaster } from "components/ErrorDetails";
import { client, endpoint, EventType } from "lib/bridgeClient";
import { SERVER_COMM_ERROR } from "lib/error";

export const getEvents = async (client: Client, type: EventType) => {
  return await client(endpoint.events(type), "GET");
};

export const useDownloadEvents = () => {
  const toastError = useErrorToaster();

  return async (type: EventType, filename: string) => {
    try {
      const response = await toast.promise(getEvents(client, type), {
        pending: "Fetching resource events . . .",
        success: {
          render() {
            return "Successfully retrieved resource events";
          },
          hideProgressBar: true,
          autoClose: 3000,
          pauseOnFocusLoss: false,
        },
        // Errors are handled in the catch block.
      });
      download(response?.data, filename, "text/plain");
    } catch (error) {
      if (error?.response?.data?.error) {
        toastError("Failed to get resource events", error?.response?.data?.error);
      } else {
        toastError(`Request failed: ${SERVER_COMM_ERROR}`, error?.response?.data);
        Sentry.captureException(error);
      }
    }
  };
};
