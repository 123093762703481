import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

interface ErrorFallbackProps {
  sx?: React.CSSProperties;
  children: React.ReactNode;
}

const Centered = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));

const ErrorFallback: React.FC<ErrorFallbackProps> = ({ sx, children }) => {
  return (
    <Centered role="alert" sx={{ ...sx }}>
      <Typography variant="body1">
        {children} Hit &quot;Refresh&quot; button to reload application. If problem persists, contact support.
      </Typography>
      <Button variant="contained" onClick={() => window.location.reload()} sx={{ marginTop: "20px" }}>
        Refresh
      </Button>
    </Centered>
  );
};

export default ErrorFallback;
