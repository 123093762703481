import * as React from "react";

import * as Sentry from "@sentry/nextjs";

import { UserContext } from "context/usercontext";
import useBridgeSWR from "hooks/BridgeSWRClient";
import { endpoint } from "lib/bridgeClient";
import useStore from "store/store";

const MyUser = ({ children }) => {
  const config = useStore((state) => state.clientConfig);
  const validCreds = useStore((state) => state.valid);

  // This is necessary to rerender this component on cred change, which is necessary
  // to invoke the api endpoint when credentials become available
  useStore((state) => state.creds);

  const dispatch = validCreds() && config?.bapiURL;
  const { data: user, error } = useBridgeSWR<MyUser>(dispatch ? endpoint.myUser : null);

  if (error) {
    Sentry.captureException(error);
    return <div className="centered-disp">Unable to determine identity. Contact support.</div>;
  }

  if (user) {
    Sentry.setUser({ email: user?.email });
  }

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export default MyUser;
