import { createContext, useState } from "react";

interface Props {
  children: React.ReactNode;
}

export enum AlertType {
  None,
  Error,
  Warning,
  Info,
  Success,
}

export type Alert = {
  type: AlertType;
  message: string;
};

type AlertCtx = {
  alert: Alert;
  setAlert: (a: Alert) => void;
};

const initialContext: AlertCtx = {
  alert: {
    type: AlertType.None,
    message: "",
  },
  setAlert: (): void => {
    throw new Error("setContext function must be overridden");
  },
};

const AlertContext = createContext<AlertCtx>(initialContext);

const AlertContextProvider = ({ children }: Props): JSX.Element => {
  const [alert, setAlert] = useState<Alert>({
    type: AlertType.None,
    message: "",
  });

  return <AlertContext.Provider value={{ alert, setAlert }}>{children}</AlertContext.Provider>;
};

export { AlertContext, AlertContextProvider };
