export const SERVER_COMM_ERROR = "trouble communicating with the server";

export const makeUserFriendly = (message: string) => {
  if (message) {
    if (message.includes("ECONNREFUSED")) {
      message = "Communication with API server failed";
    }

    if (message.includes("Unexpected")) {
      message = "Server did not return properly formatted data";
    }
  }

  return message;
};
