/* eslint-disable @typescript-eslint/no-explicit-any */

import useSWR from "swr";
import { FetcherResponse, PublicConfiguration } from "swr/dist/types";

import { client } from "lib/bridgeClient";

type Key = string | string[];

// useBridgeSWR is a hook that is meant to be used with Bridge API, it has the
// the bridge client baked in and passed as a parameter to the SWR. This also
// constructs the cache key passed on the credentials.
const useBridgeSWR = <T,>(
  key: Key,
  options?: Partial<PublicConfiguration<T, Error, (arg1: string) => FetcherResponse<T>>>
) => {
  const fetcher = async (url) => {
    const res = await client(url);
    if (!res) {
      return;
    }

    if (!res?.ok) {
      throw new Error(res?.data);
    }

    return res?.data;
  };

  return useSWR<T, Error, Key>(key, fetcher, options);
};

export default useBridgeSWR;
