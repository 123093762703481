import { createTheme } from "@mui/material";

const BaseGreen = "#00534c";
const BaseBlue = "#00A7B5";
const BaseRed = "#d27f86";

const HeaderText = "#555555";

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: BaseBlue,
      contrastText: "#ffffff",
    },
    secondary: {
      main: BaseGreen,
    },
    info: {
      main: "#00534c",
    },
    error: {
      main: BaseRed,
    },
    warning: {
      main: "#ff9800",
    },
    background: {
      default: "#E9E9E9",
    },
    text: {
      primary: "#555555",
      secondary: "#787878",
    },
  },
  spacing: 5,
  typography: {
    h1: {
      color: HeaderText,
    },
    h2: {
      color: HeaderText,
    },
    h3: {
      color: HeaderText,
    },
    h4: {
      color: HeaderText,
    },
    h5: {
      color: HeaderText,
    },
    h6: {
      color: HeaderText,
    },
  },
});

theme.components = {
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        textAlign: "center",
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        backgroundColor: "#FFF",
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      contained: {
        color: "#ffffff",
        fontWeight: "bold",
      },
    },
  },
};

export const devTheme = createTheme({
  palette: {
    primary: {
      main: "#28a933",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#2fb97f",
    },
    info: {
      main: "#289fa9",
    },
    error: {
      main: "#DE795B",
    },
    warning: {
      main: "#ff9800",
    },
    background: {
      default: "#fff",
    },
    text: {
      primary: "#555555",
      secondary: "#787878",
    },
  },
});

export const testTheme = createTheme({
  palette: {
    primary: {
      main: "#8e17b6",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#3e17b6",
    },
    info: {
      main: "#b6178e",
    },
    error: {
      main: "#DE795B",
    },
    warning: {
      main: "#ff9800",
    },
    background: {
      default: "#fff",
    },
    text: {
      primary: "#555555",
      secondary: "#787878",
    },
  },
});

export default theme;
