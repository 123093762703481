import * as React from "react";

import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import { getClientConfig } from "config/client";
import { MyOrgContext } from "context/myorgcontext";
import { UserContext } from "context/usercontext";
import useStore from "store/store";

const LoadingScreen = ({ children }) => {
  const myUser = React.useContext(UserContext);
  const myOrg = React.useContext(MyOrgContext);
  const creds = useStore((state) => state.creds);

  if (!myUser || !myOrg || !creds || !getClientConfig()) {
    return (
      <>
        <Box
          sx={{
            minHeight: "100px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LinearProgress sx={{ width: "60%" }} />
          <Typography sx={{ marginTop: "10px" }}>Loading ...</Typography>
        </Box>
      </>
    );
  }

  return children;
};

export default LoadingScreen;
