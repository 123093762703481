import * as React from "react";

import useSWR from "swr";

import { ClientConfig, setClientConfig } from "config/client";

const ClientConfig = ({ children }) => {
  const fetcher = (url) => fetch(url).then((r) => r.json());
  const { data, error } = useSWR<ClientConfig>("/api/client-config", fetcher, {
    revalidateOnFocus: false,
  });

  React.useEffect(() => {
    // Need to handle storing to state async otherwise React complains with:
    // 'Cannot update a component while rendering a different component warning'
    if (data) {
      setClientConfig(data);
    }
  }, [data]);

  if (error) {
    return <div className="centered-disp">Unable to get client config</div>;
  }

  return children;
};

export default ClientConfig;
