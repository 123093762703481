import React from "react";

import { ThemeProvider } from "@mui/material";
import * as Sentry from "@sentry/nextjs";
import { SessionProvider } from "next-auth/react";
import Error from "next/error";
import Head from "next/head";
import { Router } from "next/router";
import nProgress from "nprogress";
import { SWRConfig } from "swr";

import AccessToken from "components/AccessToken";
import Auth from "components/Auth";
import ClientConfig from "components/ClientConfig";
import ErrorFallback from "components/ErrorFallback";
import Layout from "components/Layout";
import LoadingScreen from "components/LoadingScreen";
import MyOrg from "components/MyOrg";
import MyInfo from "components/MyUser";
import { makeUserFriendly } from "lib/error";
import Styles from "styles/global";
import theme from "theme";

import "react-toastify/dist/ReactToastify.css";
import "styles/loader.css";
import "styles/nprogress.css";
import "styles/globals.css";

Router.events.on("routeChangeStart", nProgress.start);
Router.events.on("routeChangeError", nProgress.done);
Router.events.on("routeChangeComplete", nProgress.done);

function Fallback() {
  return <ErrorFallback sx={{ margin: "100px 20px 0px 20px" }}>Uh-oh! Something went wrong!</ErrorFallback>;
}

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  if (pageProps.error) {
    Sentry.captureException(pageProps.error);
    return <Error statusCode={pageProps.error?.statusCode || 500} title={makeUserFriendly(pageProps.error?.message)} />;
  }

  return (
    <Sentry.ErrorBoundary fallback={Fallback} showDialog>
      <SWRConfig
        value={{
          onError: (error) => {
            Sentry.captureException(error);
          },
        }}
      >
        <Head>
          <title>Metworx Next</title>
          <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        </Head>
        <ThemeProvider theme={theme}>
          <Styles />
          <SessionProvider session={session}>
            {Component.noLayout ? (
              <Component {...pageProps} />
            ) : (
              <Auth>
                <AccessToken>
                  <ClientConfig>
                    <MyInfo>
                      <MyOrg>
                        <LoadingScreen>
                          <Layout>
                            <Component {...pageProps} />
                          </Layout>
                        </LoadingScreen>
                      </MyOrg>
                    </MyInfo>
                  </ClientConfig>
                </AccessToken>
              </Auth>
            )}
          </SessionProvider>
        </ThemeProvider>
      </SWRConfig>
    </Sentry.ErrorBoundary>
  );
}

export default MyApp;
