import Button, { ButtonProps } from "@mui/material/Button";
import { default as MUIMenuItem, MenuItemProps } from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.dark,
  "& .MuiButton-endIcon": {
    marginLeft: 0,
    marginRight: "2px",
  },
}));

export const MenuButton = styled((props: ButtonProps) => (
  <StyledButton {...props} sx={{ padding: 0, textTransform: "none" }} />
))(() => ({}));

export const MenuItem = styled((props: MenuItemProps) => (
  <MUIMenuItem
    sx={{
      fontSize: "0.875rem",
      svg: {
        marginRight: "5px",
      },
    }}
    {...props}
  />
))(() => ({}));
