import { alpha } from "@mui/material";
import GlobalStyles from "@mui/material/GlobalStyles";
import { makeStyles } from "@mui/styles";

import theme from "theme";
const Styles = () => (
  <GlobalStyles
    styles={(theme) => ({
      ":root": {
        "--primary": theme.palette.primary.main,
      },

      body: {
        color: "#333",
        fontFamily: "Roboto,sans-serif",
      },

      footer: {
        display: "block",
        textAlign: "center",
        padding: "10px 0 40px 0",
        marginTop: "60px",
        color: "#777",
        borderTop: "1px solid #ddd",
      },

      /* Used in page layout */
      ".content": {
        maxWidth: "1200px",
        width: "100%",
      },

      /* Used in page layout */
      ".main": {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },

      ".centered-disp": {
        display: "flex",
        height: "50%",
        alignItems: "center",
        justifyContent: "center",
      },

      ".centered-page": {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },

      ".form-container": {
        marginTop: "40px",
        width: "800px",
        borderRadius: "15px",
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        padding: "0 40px 40px 40px",
      },
    })}
  />
);

export const useStyles = makeStyles(() => ({
  styledPaper: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  containerLoad: {
    animation: `$fadeIn 3s`,
    paddingRight: "20px",
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
}));

export default Styles;
