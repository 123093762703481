import * as Sentry from "@sentry/nextjs";
import { ToastContainer } from "react-toastify";

import AlertBanner from "components/Alert";
import ErrorFallback from "components/ErrorFallback";
import Footer from "components/Footer";
import Header from "components/Header";

function Fallback() {
  return <ErrorFallback sx={{ margin: "100px 20px 0px 20px" }}>Uh-oh! Something went wrong!</ErrorFallback>;
}

const Layout = ({ children }) => {
  return (
    <div className="main">
      <div className="content">
        <Header />
        <AlertBanner />
        <div style={{ minHeight: "500px", marginTop: "10px" }}>
          <Sentry.ErrorBoundary fallback={Fallback} showDialog>
            <ToastContainer />
            {children}
          </Sentry.ErrorBoundary>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
