import * as React from "react";

import { faUser, faEdit, faHdd } from "@fortawesome/free-solid-svg-icons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import Router from "next/router";

import { MenuButton, MenuItem } from "components/header/Menu";
import { FontAwesomeIconWithTheme } from "components/ui/IconWrapper";

const UserDropdown: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAccount = () => {
    Router.push("/account");
    setAnchorEl(null);
  };

  const handleDisks = () => {
    Router.push("/disks");
    setAnchorEl(null);
  };

  return (
    <div>
      <MenuButton
        id="user-dropdown-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        <FontAwesomeIconWithTheme icon={faUser} style={{ marginLeft: "5px", marginRight: "5px" }} />
        My Info
      </MenuButton>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            minWidth: "150px",
          },
        }}
      >
        <MenuItem onClick={handleAccount} disableRipple>
          Account
        </MenuItem>
        <MenuItem onClick={handleDisks} disableRipple>
          Disks
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserDropdown;
