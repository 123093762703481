import * as React from "react";

import { default as MUIAlert } from "@mui/material/Alert";
import { styled } from "@mui/material/styles";

import { AlertContext, Alert as AlertCtx, AlertType } from "context/alertcontext";

const AlertMessage = styled("div")(() => ({
  minHeight: "50px",
}));

const AlertBanner: React.FC = () => {
  const { alert, setAlert } = React.useContext(AlertContext);

  const onClose = () => {
    const a: AlertCtx = {
      type: AlertType.None,
      message: "",
    };
    setAlert(a);
  };

  switch (alert?.type) {
    case AlertType.Error:
      return (
        <AlertMessage data-testid="error-alert-type">
          <MUIAlert severity="error" onClose={onClose}>
            {alert.message}
          </MUIAlert>
        </AlertMessage>
      );
    case AlertType.Warning:
      return (
        <AlertMessage data-testid="warning-alert-type">
          <MUIAlert severity="warning" onClose={onClose}>
            {alert.message}
          </MUIAlert>
        </AlertMessage>
      );
    case AlertType.Info:
      return (
        <AlertMessage data-testid="info-alert-type">
          <MUIAlert severity="info" onClose={onClose}>
            {alert.message}
          </MUIAlert>
        </AlertMessage>
      );
    case AlertType.Success:
      return (
        <AlertMessage data-testid="success-alert-type">
          <MUIAlert severity="success" onClose={onClose}>
            {alert.message}
          </MUIAlert>
        </AlertMessage>
      );
    case AlertType.None:
      return <AlertMessage data-testid="none-alert-type" />;
    default:
      return <AlertMessage data-testid="none-alert-type" />;
  }

  return null;
};

export default AlertBanner;
