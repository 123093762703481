import * as React from "react";

import * as Sentry from "@sentry/nextjs";
import Cookies from "js-cookie";
import { useSession, signIn } from "next-auth/react";
import Router from "next/router";
import useSWR from "swr";

const Auth = ({ children }) => {
  const { status } = useSession();

  const fetcher = (url) => fetch(url).then((r) => r.json());
  const { data: session, isValidating } = useSWR<Session>("/api/auth/session", fetcher);
  const isLoading = status === "loading" || (!session && isValidating);

  React.useEffect(() => {
    if (isLoading) return; // Do nothing while loading

    const d = Date.now();
    if (!session?.accessToken || d >= Date.parse(session?.expires)) {
      Router.push("/auth/signin?msg=Session missing/expired. Please login.");
      return;
    }

    if (session?.error === "RefreshAccessTokenError") {
      const fingerprint = Cookies.get("fingerprint");
      signIn(fingerprint); // Force sign in to hopefully resolve error
      return;
    }
  }, [isLoading, session]);

  if (!isLoading && !session) {
    Sentry.captureMessage("Failed to get access token, unable to establish session");

    return <div className="centered-disp">Failed to authenticate. Try refreshing page.</div>;
  }

  return children;
};

export default Auth;
