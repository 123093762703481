import create, { StateCreator } from "zustand";

import { ClientConfig } from "config/client";

interface CredSlice {
  creds: Creds;
  setCreds: (creds: Creds) => void;
  valid: () => boolean;
}

const createCredSlice: StateCreator<CredSlice, [], []> = (set, get) => ({
  creds: {
    token: "",
    expires: 0,
  },
  setCreds: (creds) => set({ creds: creds }),
  valid: () => {
    const creds = get().creds;
    if (!creds?.token || !creds.expires || creds?.expires <= 0) {
      return false;
    }

    return true;
  },
});

interface ClientConfigSlice {
  clientConfig: ClientConfig;
  setClientConfig: (config: ClientConfig) => void;
}

const createClientConfigSlice: StateCreator<ClientConfigSlice, [], []> = (set) => ({
  clientConfig: {
    bapiURL: null,
    refreshTokenDelay: 500,
  },
  setClientConfig: (config) => set({ clientConfig: config }),
});

interface DashboardSlice {
  showUpdateFormForWorkflow: Map<string, boolean>;
  showWorkflowUpdateForm: (workflow: string) => void;
  hideWorkflowUpdateForm: (workflow: string) => void;
  hideAllUpdateWorkflowForms: () => void;
}

const createDashboardSlice: StateCreator<DashboardSlice, [], []> = (set, get) => ({
  showUpdateFormForWorkflow: new Map([]),
  showWorkflowUpdateForm: (workflow: string) => {
    const current = get().showUpdateFormForWorkflow;
    set({ showUpdateFormForWorkflow: new Map(current).set(workflow, true) });
  },
  hideWorkflowUpdateForm: (workflow: string) => {
    const current = get().showUpdateFormForWorkflow;
    set({ showUpdateFormForWorkflow: new Map(current).set(workflow, false) });
  },
  hideAllUpdateWorkflowForms: () => {
    set({ showUpdateFormForWorkflow: new Map([]) });
  },
});

const useCredStore = create<CredSlice & ClientConfigSlice & DashboardSlice>()((...a) => ({
  ...createCredSlice(...a),
  ...createClientConfigSlice(...a),
  ...createDashboardSlice(...a),
}));

export default useCredStore;
