import * as React from "react";

import { faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import { alpha } from "@mui/material";
import { styled } from "@mui/material/styles";
import Image from "next/image";
import Link from "next/link";
import Router from "next/router";

import logo from "assets/images/metworx-logo.svg";
import { MenuButton } from "components/header/Menu";
import OrgAdminDropdown from "components/header/OrgAdminDropdown";
import UserDropdown from "components/header/UserDropdown";
import { FontAwesomeIconWithTheme } from "components/ui/IconWrapper";
import { UserContext } from "context/usercontext";
import useStore from "store/store";

const Nav = styled("div")(() => ({
  width: "100%",
  margin: "10px auto 20px",
  padding: "40px 0 2px 0",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
  borderBottom: "1px solid #dddddd",
}));

const Logo = styled("div")(() => ({
  width: "200px",
}));

const LinksContainer = styled("div")(() => ({
  display: "flex",
  justifyItems: "flex-end",
}));

const LinkWrapper = styled("div")(({ theme }) => ({
  marginLeft: theme.spacing(6),

  a: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
    marginRight: theme.spacing(1),
  },

  "&:hover": {
    borderRadius: "15px",
    backgroundColor: alpha(theme.palette.secondary.main, 0.1),
  },

  svg: {
    color: theme.palette.secondary.main,
  },
}));

const Header: React.FC = () => {
  const user = React.useContext(UserContext);
  const hideAllUpdateWorkflowForms = useStore((state) => state.hideAllUpdateWorkflowForms);

  const navToDashboard = () => {
    hideAllUpdateWorkflowForms();

    // Only route if not on dashboard already
    if (Router.route !== "/") {
      Router.push("/");
    }
  };

  return (
    <Nav>
      <Logo onClick={() => navToDashboard()} style={{ cursor: "pointer" }}>
        <a>
          <Image src={logo} alt="logo" />
        </a>
      </Logo>
      <LinksContainer>
        <LinkWrapper onClick={() => navToDashboard()}>
          <a>
            <FontAwesomeIconWithTheme icon={faTachometerAlt} style={{ marginLeft: "5px", marginRight: "5px" }} />
            <MenuButton>Dashboard</MenuButton>
          </a>
        </LinkWrapper>
        <LinkWrapper>
          <UserDropdown />
        </LinkWrapper>
        {user?.is_org_admin === true && (
          <LinkWrapper>
            <OrgAdminDropdown />
          </LinkWrapper>
        )}
        <LinkWrapper>
          <Link href="/api/auth/federated-logout">
            <a>
              <MenuButton>Sign Out</MenuButton>
            </a>
          </Link>
        </LinkWrapper>
        <LinkWrapper>
          <a href="https://metworx.atlassian.net/servicedesk/customer/portal/6" target="_blank" rel="noreferrer">
            <MenuButton>Support</MenuButton>
          </a>
        </LinkWrapper>
        <LinkWrapper>
          <a href="https://info.metrumrg.com/contact" target="_blank" rel="noreferrer">
            <MenuButton>Contact</MenuButton>
          </a>
        </LinkWrapper>
      </LinksContainer>
    </Nav>
  );
};

export default Header;
