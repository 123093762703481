import * as React from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";

const Error = styled("div")(() => ({
  color: "#e74c3c",
}));

export const ErrorWithDetails = ({ message, error }: { message: string; error: string | string[] }) => {
  const [showingError, setShowingError] = React.useState(false);

  let displayError: string[] = [];
  if (typeof error === "string") {
    displayError.push(error);
  } else {
    displayError = error;
  }

  return (
    <>
      <div>{message}</div>
      <Typography
        id="show-error-button"
        onClick={() => setShowingError(!showingError)}
        variant="body2"
        color="error"
        sx={{ marginBottom: "10px" }}
      >
        {showingError ? "Hide Error" : "Show Error"}
        <KeyboardArrowDownIcon sx={{ position: "sticky", top: "50%" }} />
      </Typography>
      {showingError && (
        <Error>
          {displayError.map((err, index) => (
            <div key={err + index}>{err}</div>
          ))}
        </Error>
      )}
    </>
  );
};

export const useErrorToaster = () => {
  const toaster = (message: string, error: string | string[]) => {
    toast.error(<ErrorWithDetails message={message} error={error} />, { closeOnClick: false });
  };

  return toaster;
};
